import * as _lodash2 from "lodash";

var _lodash = "default" in _lodash2 ? _lodash2.default : _lodash2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

var exports = {};
var _ = _lodash;
var p = _path;
var autocompleteScript = exports = {};

autocompleteScript.bashScript = function (exec, zshCompat) {
  zshCompat = !!zshCompat;
  var fname = p.basename(exec, ".js");
  var name = p.basename(exec);
  var path = p.normalize(exec);
  return (zshCompat ? "#compdef " + name + "\nautoload -U +X bashcompinit && bashcompinit\n" : "") + "_" + fname + "()\n" + "{\n" + "  local ARGS COMPGENCMD\n" + "\n" + "  cur=${COMP_WORDS[COMP_CWORD]};\n" + "\n" + "  ARGS=(--autocomplete-index $COMP_CWORD)\n" + "  for word in ${COMP_WORDS[@]}; do\n" + "    ARGS=(${ARGS[@]}  --autocomplete-words=\"$word\")\n" + "  done\n" + "\n" + "  COMPGENCMD=( $(" + exec + " \"${ARGS[@]}\") )\n" + "\n" + "  COMPREPLY=( $(eval ${COMPGENCMD[@]}) )\n" + "\n" + "  return 0;\n" + "}\n" + "complete -o nospace -F _" + fname + " " + name;
};

export default exports;