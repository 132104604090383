import * as _minimist2 from "minimist";

var _minimist = "default" in _minimist2 ? _minimist2.default : _minimist2;

import * as _lodash2 from "lodash";

var _lodash = "default" in _lodash2 ? _lodash2.default : _lodash2;

import _argument from "./argument";
import _autocomplete from "./autocomplete.js";
import _cli from "./cli";
import _command from "./command";
import _option from "./option";
import _parsers from "./parsers";
import _utils from "./utils";
var exports = {};
var minimist = _minimist;
var _ = _lodash;
var argument = _argument.argument;
var autocomplete = _autocomplete;
var cli = _cli.cli;
var command = _command.command;
var flag = _option.flag;
var option = _option.option;
var parse = _cli.parse;
var parsers = _parsers;
var utils = _utils;
var cliparse = exports = {};
cliparse.argument = argument;
cliparse.autocomplete = autocomplete;
cliparse.cli = cli;
cliparse.command = command;
cliparse.flag = flag;
cliparse.option = option;
cliparse.parse = parse;
cliparse.parsers = parsers;
export default exports;